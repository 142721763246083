<template>
  <div class="container-fluid">
    <page-title :title="`Add Company`" />

    <div class="row justify-content-cente">
      <div class="col-md-8">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="save">
              <div class="">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Company Name</label>
                      <input
                        required
                        type="text"
                        class="form-control"
                        v-model="company.name"
                        placeholder="Company Name"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Registration Code</label>
                      <input
                        required
                        type="text"
                        class="form-control"
                        v-model="company.crp"
                        placeholder="e.g CPR/2001/2650"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Company Email</label>
                      <input
                        required
                        type="email"
                        class="form-control"
                        v-model="company.email"
                        placeholder="Company Email"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Company Phone Number</label>
                      <input
                        required
                        type="text"
                        class="form-control"
                        v-model="company.phone_number"
                        placeholder="Company Phone Number"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Company Physical Address</label>
                      <input
                        required
                        type="text"
                        class="form-control"
                        v-model="company.physical_address"
                        placeholder="Physical Address"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Company Postal Address</label>
                      <input
                        required
                        type="text"
                        class="form-control"
                        v-model="company.postal_address"
                        placeholder="Postal Address"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <a href="#" class="btn btn-info btn-block mt-3" @click.prevent="downloadResolution">
                        <i class="fa fa-download mr-2" aria-hidden="true"></i>Download Company Resolution Template
                      </a>
                      <div id="download-resolution"></div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Company Resolution</label>
                      <input
                        type="file"
                        class="form-control"
                        ref="company_resolution"
                        id="company_resolution_file"
                      />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <button class="btn btn-secondary float-right">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      company: {
        name: "",
      },
      company_resolution: "",
    };
  },

  methods: {
    selectFile() {
      // eslint-disable-next-line
      $("#company_resolution_file").click();
    },

    downloadResolution() {
      this.$axios
        .get("/api/v1/dashboard/companies/resolution")
        .then(response => {
          let el = document.createElement("a");
          el.setAttribute("href", response.data);
          el.setAttribute("target", "_blank");
          document.getElementById("download-resolution").appendChild(el);
          el.click();
          document.getElementById("download-resolution").removeChild(el);
        })
    },

    save() {
      this.$loader.start()

      let company_resolution = this.$refs.company_resolution.files[0];

      var myData = new FormData();
      myData.append("name", this.company.name);
      myData.append("crp", this.company.crp);
      myData.append("email", this.company.email);
      myData.append("phone_number", this.company.phone_number);
      myData.append("physical_address", this.company.physical_address);
      myData.append("postal_address", this.company.postal_address);
      myData.append("company_resolution", company_resolution);

      this.$axios
        .post("/api/v1/dashboard/companies", myData, {
          headers: { "content-type": "multipart/form-data" },
        })
        .then((response) => {
          this.$loader.stop()
          this.$router.push({
            name: "companies.show",
            params: { id: response.data.unique_id },
          });
        })
        .catch((error) => {
          this.$alert.httpError(error.response);
        });
    },
  },
};
</script>
